import React from "react"
import { Link } from "gatsby"
import { useMixpanel } from "gatsby-plugin-mixpanel"

const Checkout = () => {
  const mixpanel = useMixpanel()
  return (
    <div className="w-full h-screen gradient md:p-16">
      <div
        className="mx-auto md:w-1/3 bg-light-green border-t-4 border-light-blue rounded-b text-teal-900 px-4 py-4 shadow-md mb-8"
        role="alert"
      >
        <div className="flex">
          <div className="py-1">
            <svg
              className="fill-current h-6 w-6 text-blue mr-4"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
            >
              <path d="M2.93 17.07A10 10 0 1 1 17.07 2.93 10 10 0 0 1 2.93 17.07zm12.73-1.41A8 8 0 1 0 4.34 4.34a8 8 0 0 0 11.32 11.32zM9 11V9h2v6H9v-4zm0-6h2v2H9V5z" />
            </svg>
          </div>
          <div>
            <p className="text-black font-bold">Early access spots are full!</p>
            <p className="text-black text-sm">
              We can't currently handle any more users for this period. But you
              can still join the wait list for the next wave of early access.
            </p>
          </div>
        </div>
      </div>
      <form
        method="post"
        name="buy-access"
        className="w-4/5 md:w-2/3  max-w-lg mx-auto shadow-custom p-8 rounded bg-white"
        data-netlify="true"
        onSubmit={() => mixpanel.track("finished-checkout")}
        action="/"
      >
        <input
          type="hidden"
          className="hidden"
          name="form-name"
          value="buy-access"
        />
        <label
          className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
          for="grid-first-name"
        >
          E-mail
        </label>
        <input
          className="border-2 p-4 w-full text-gray-700 mr-3 py-1 px-2 leading-tight focus:outline-none"
          type="text"
          name="email"
          placeholder="email@mail.com"
          aria-label="Email Adress"
        />
        <input
          className="mt-8 flex-shrink-0 bg-dark-green hover:shadow-custom border-dark-green hover:text-black text-sm border-4 text-white py-1 px-2 rounded"
          type="submit"
          value="Join list"
        />
        <Link
          to="/"
          className="flex-shrink-0 border-transparent border-4 text-green text-sm py-1 px-2 rounded"
        >
          Cancel
        </Link>
      </form>
    </div>
  )
}

export default Checkout
